.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.custom-select:focus .ant-select-selector,
.custom-select:not(:focus) .ant-select-selector {
  border-color: rgb(241, 90, 90) !important;
  border-width: 2px;
  border-radius: 20px;
}

.custom-select:hover .ant-select-selector {
  border-color: rgb(243, 60, 60) !important;
  transition: box-shadow 0.3s !important;
  box-shadow: 0 2px 4px rgb(243, 98, 98) !important;
}

.ant-select-item-option:hover:not(.ant-select-item-option-disabled) {
  background-color: rgb(243, 98, 98) !important;
  
  /* Add any other styles for the hover effect */
}

.rc-virtual-list-holder-inner{
  background-color: '#fff' !important;
}

.custom-select .ant-select-selection-placeholder:not(.ant-select-selection-item) {
  color: black;
  font-weight: 600;
  font-family: sans-serif;
}

.cardstyle {
  cursor: pointer;
  height: 310px;
  transition: border 0.3s, box-shadow 0.3s; /* Transition both border and box-shadow properties */
  border: 8px solid transparent; /* Initial border */
  box-shadow: 0 0 0 transparent; /* Initial box shadow */
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}
.image{
  width: 100% !important;
  object-fit: cover !important;
  height: fit-content !important;
}

.cardstyle:hover {
  border: 8px solid #fff; /* Thick border on hover */
  box-shadow: 0 0 10px rgba(246, 0, 0, 0.9); /* Add a box shadow on hover */
}

.custom-select .ant-select-selection-placeholder:not(.ant-select-selection-item) {
  color: black;
  font-weight: 600;
  font-family: 'Comic Sans MS' !important;
}
:where(.css-dkbvqv).ant-form-item .ant-form-item-label >label {
  font-family: 'Comic Sans MS' !important;
}
:where(.css-dkbvqv).ant-input {
  font-family: 'Comic Sans MS' !important;
}
:where(.css-dkbvqv).ant-btn-primary.ant-btn-background-ghost {
  border-color: #ff162c !important;
  font-family: 'Comic Sans MS' !important;
}

.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.ant-card-body{
  display: contents !important;
}
.itemCardsImage {
  height: 200px !important;
  object-fit: contain !important;
}

.iteamCardBadge {
  margin-left: '50px' !important;
}
.btn-add{
  font-size: 10px;
  font-weight: 700;
}

.btn-add:hover {
  
  transition: box-shadow 0.3s;
  
}
.custom-rate {
  font-size: 16px !important;
}
.shopingCartIcon {
  font-size: 25px;
  margin-right: 18px;
}

.contactfont {
  font-family: Georgia, serif;
  font-size: 18px;
}
.imagetext{
  width: fit-content;
}

.card .bg-image{
  display: block !important;
}

:where(.css-dkbvqv).ant-image{
  position: relative !important;
    display: block !important;
    width: 100% !important;
}

:where(.css-dkbvqv).ant-image .ant-image-img{
  width: 100% !important;
    height: 320px !important;
    object-fit: cover !important;
}

.pageContent {
  display: flex;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
}

.btn-submit{
  font-size: 15px;
  font-weight: 700;
}

.btn-submit:hover {
  background-color: black !important;
  transition: box-shadow 0.3s;
  box-shadow: 0 1px 4px rgb(243, 90, 90) !important;
}

.centered-card-title {
  text-align: center;
  font-size: 18px; /* Adjust the font size as needed */
  font-weight: 500; /* Add font-weight if needed */
  /* Add any other styling as needed */
}

.custom-header {
  border: none !important;
  text-align: left !important;
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-underline {
  display: inline-block;
  position: relative;
  color: #192124;
}

.hover-underline:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #101315;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.social-icon {
  
   box-sizing: border-box;
  -moz-border-radius: 138px;
  -webkit-border-radius: 138px;
  border-radius: 138px;
  border: 5px solid;
  text-align: center;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 1px;
  padding-top: 11px;
  transition: all 0.5s;
}
.social-icon:hover {
    transform: rotate(360deg)scale(1.3);
  }
  /* Facebook Button Styling */
 .facebook {
    font-size: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-color: #3b5998;
    background-color: #fff;
    color: #ffffff;
   
  }
  .facebook:hover {
    background-color: #ffffff;
    color: #3b5998;
  }
  .twitter {
    font-size: 22px;
    padding-top: 10px;
    padding-left: 2px;
    border-color: #55acee;
    background-color: #55acee;
    color: #ffffff;
    
  }
  .twitter:hover {
    background-color: #ffffff;
    color: #55acee;
  }
.btn-add-message {
  font-weight: 700;
  font-size: .7em;
}
  .btn-add-message:hover {
    background-color: rgb(241, 60, 60) !important;
  transition: box-shadow 0.3s;
  box-shadow: 0 1px 4px rgb(33, 30, 30) !important;
  }
  
  .custom-tooltip {
    background-color: rgb(243, 70, 70) !important;
    
  }
  .arrow-btn {
   
  }

  .ant-carousel .slick-prev, .ant-carousel .slick-next {
    
    color: rgb(237, 14, 14) !important;
    font-size: 2.5rem !important;
    font-weight: bolder;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 50px !important;
    cursor: pointer !important;
    z-index: 10 !important;
    opacity: 1 !important; /* Ensure the buttons are always visible */
    transition: none !important; /* Disable any transition effects */
  }
  
  
  

  .gradient-custom-2 {
    /* fallback for old browsers */
    background: #fccb90;
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
  }
  @media (min-width: 1025px) {
    .h-custom {
    height: 100vh !important;
    }
    }
  
  @media (min-width: 768px) {
    .gradient-form {
      height: 100vh !important;
    }
  }
  @media (min-width: 769px) {
    .gradient-custom-2 {
      border-top-right-radius: .3rem;
      border-bottom-right-radius: .3rem;
    }
  }

  .error-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .custom-error-message {
    /* Add your custom styles for the error message */
    background-color: #ff4d4f; /* Ant Design's error color */
    color: #fff;
    text-align: center;
    padding: 10px 20px;
    border-radius: 4px;
  }
.App-link {
  color: #61dafb;
}

.product-container {
  margin-bottom: 20px;
}

.main-image-container {
  position: relative;
}

.main-image {
  width: 100%;
  height: auto;
}


.sub-images-container {
  display: flex;
  margin-top: 10px;
}

.sub-image-container {
  margin-right: 1em;
}
.sub-image {
  width: 50px; /* Adjust the width of the sub-images as needed */
  height: 50px;
  margin-right: 5px; /* Adjust the margin between sub-images as needed */
  cursor: pointer;
  object-fit: cover;
}

.sub-image:last-child {
  margin-right: 0;
}
.sub-image-container.with-margin {
  margin-right: 1em;
}

.sub-images-container2 {
  width: 100%;
    display: flex;
    
}

:where(.css-dkbvqv).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error{
  border-color: green !important;
}

:where(.css-dkbvqv).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name{
  color: #101315 !important;
}

:where(.css-dkbvqv).ant-tooltip {
  visibility: hidden !important;
}

.sub-image-wrapper {
  width: calc(30% - 50px); /* Adjust width based on your preference */
  border: 1px solid #ccc; /* Border style */
  box-sizing: border-box; /* Include padding in the width calculation */
  
}

.sub-image2 {
  width: 100%;
  height: 80px;
  object-fit: cover;
}

.gradient-custom {
  /* fallback for old browsers */
  background: #f29c9c;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to top left, rgb(242, 156, 156), rgb(242, 156, 156));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to top left, rgb(242, 156, 156), rgb(242, 156, 156))
}

body {
  background-color: #eee;
}

@media (max-width: 767.98px) {
  .border-sm-start-none {
    border-left: none !important;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.responsive-image {
  width: 100%;
  height: 25rem;
  object-fit: cover;
}

@media (max-width: 1028px) and (max-height: 1380px) {
  .responsive-image {
    object-fit: contain;
    width: 100%;
  height: 28rem;
  }
}
